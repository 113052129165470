import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStatus, putStatusDocuments } from "./services";

export const statusByType = createAsyncThunk(
  "status/getStatusByType",
  async (type, { rejectWithValue }) => {
    try {
      const { data } = await getStatus(type);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const editStatusDocuments = createAsyncThunk(
  "status/editStatusDocuments",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await putStatusDocuments(body);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  data: [],
  message: null,
  type: "",
  status: "idle",
};

const status = createSlice({
  name: "status",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(statusByType.pending, (state) => {
        return (state = { ...state, status: "loading", type: "statusByType" });
      })
      .addCase(statusByType.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          data: action.payload.message,
          type: "statusByType",
        });
      })
      .addCase(statusByType.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          data: action.payload.message,
          type: "statusByType",
        });
      })
      .addCase(editStatusDocuments.pending, (state) => {
        return (state = {
          ...state,
          status: "loading",
          type: "editStatusDocuments",
        });
      })
      .addCase(editStatusDocuments.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          message: action.payload.message,
          type: "editStatusDocuments",
        });
      })
      .addCase(editStatusDocuments.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          message: action.payload.message,
          type: "editStatusDocuments",
        });
      });
  },
});

export default status.reducer;
